<template>
  <!-- 请勿随意格式化代码！！！！！！！！！！！！！ -->
  <!-- 请勿随意格式化代码 -->
  <!-- 请勿随意格式化代码 -->
  <!-- 请勿随意格式化代码 -->
  <!-- 请勿随意格式化代码 -->
  <!-- 请勿随意格式化代码 -->
  <!-- 请勿随意格式化代码 -->
  <!-- 请勿随意格式化代码 -->
  <!-- 请勿随意格式化代码 -->
  <div class="ele-table">
    <div class="slot-w">
      <slot></slot>
    </div>
    <div class="tw" id="tw">
      <div class="" v-if="!hideRowSelection && showRowSelectionTips">
        <a-alert :type="'info'" :showIcon="false" style="margin-bottom: 5px; padding: 4px 15px 4rpx 37px">
          <template slot="message">
            <span v-html="l('GridSelectedXItemsTips', selectedRowKeys.length)"></span>
            <a @click="clearData()" class="ml-md">{{ l("ClearEmpty") }}</a>
            <a-divider type="vertical"></a-divider>
            <a @click="refreshData()">{{ l("Refresh") }}</a>
          </template>
        </a-alert>
      </div>
      <el-table ref="myTable" :data="tableData" style="width: 100%" row-key="id" :stripe="stripe" :border="border"
        :size="size" :height="tableHeight" :max-height="maxHeight" default-expand-all v-if="(columns.length)"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :header-row-style="headerRowStyle"
        :row-style="rowStyle" :cell-style="cellStyle" @select="handleSelectChange" @header-click="handleHeaderClick"
        @sort-change="handleSortChange" @header-dragend="handleHeaderDragend" @select-all="handleSelectAll">
        <el-table-column v-if="!hideRowSelection" type="selection" width="40" align="center" fixed>
        </el-table-column>
        <template v-for="(item, index) in (myColumns.length ? myColumns : columns)">
          <el-table-column v-if="returnType(item.type ? item.type : '') === 'time' &&
            item.dataIndex !== 'actions'
            " :key="'column' + index" :prop="item.dataIndex" :label="item.title" :sortable="item.sorter && item.sorter !== null ? item.sorter : false
    " :width="item.width" :min-width="item.minWidth ? item.minWidth : '120'" :fixed="item.fixed"
            :resizable="item.resizable ? item.resizable : true" :show-overflow-tooltip="item.showOverflowTooltip ? item.showOverflowTooltip : true
              " :align="item.align ? item.align : 'center'"
            :header-align="item.headerAlign ? item.headerAlign : 'center'"
            :formatter="(row, column) => dateTimeFormat(row[column.property], 'YYYY-MM-DD HH:mm:ss')">
          </el-table-column>

          <el-table-column v-if="returnType(item.type ? item.type : '') === 'date' &&
            item.dataIndex !== 'actions'
            " :key="'column' + index" :prop="item.dataIndex" :label="item.title" :sortable="item.sorter && item.sorter !== null ? item.sorter : false
    " :width="item.width" :min-width="item.minWidth ? item.minWidth : '120'" :fixed="item.fixed"
            :resizable="item.resizable ? item.resizable : true" :show-overflow-tooltip="item.showOverflowTooltip ? item.showOverflowTooltip : true
              " :align="item.align ? item.align : 'center'"
            :header-align="item.headerAlign ? item.headerAlign : 'center'"
            :formatter="(row, column) => dateTimeFormat(row[column.property], 'YYYY-MM-DD')">
          </el-table-column>

          <el-table-column v-if="returnType(item.type ? item.type : '') === 'normal' &&
            item.dataIndex !== 'actions'
            " :key="'column' + index" :prop="item.dataIndex" :label="item.title" :sortable="item.sorter && item.sorter !== null ? item.sorter : false
    " :width="item.width" :min-width="item.minWidth ? item.minWidth : '120'" :fixed="item.fixed"
            :resizable="item.resizable ? item.resizable : true" :show-overflow-tooltip="item.showOverflowTooltip ? item.showOverflowTooltip : true
              " :align="item.align ? item.align : 'center'"
            :header-align="item.headerAlign ? item.headerAlign : 'center'"
            :formatter="item.formatter ? dateFormat : null">
          </el-table-column>

          <el-table-column v-if="returnType(item.type ? item.type : '') === 'custom' &&
            item.dataIndex !== 'actions'
            " :key="'column' + index" :prop="item.dataIndex" :label="item.title" :sortable="item.sorter && item.sorter !== null ? item.sorter : false
    " :width="item.width" :min-width="item.minWidth ? item.minWidth : '120'" :fixed="item.fixed"
            :resizable="item.resizable ? item.resizable : true" :show-overflow-tooltip="item.showOverflowTooltip ? item.showOverflowTooltip : true
              " :align="item.align ? item.align : 'center'"
            :header-align="item.headerAlign ? item.headerAlign : 'center'"
            :formatter="item.formatter ? dateFormat : null">
            <template slot-scope="scope">
              {{ item.customRender ? item.customRender(scope.row[`${item.dataIndex}`], scope.row, index) :
                scope.row[`${item.dataIndex}`] }}
            </template>
          </el-table-column>

          <el-table-column v-if="returnType(item.type ? item.type : '') === 'tag'" :key="'column' + index"
            :prop="item.dataIndex" :label="item.title" :sortable="item.sorter && item.sorter !== null ? item.sorter : false
              " :width="item.width" :min-width="item.minWidth ? item.minWidth : '120'" :fixed="item.fixed"
            :resizable="item.resizable ? item.resizable : true" :show-overflow-tooltip="item.showOverflowTooltip ? item.showOverflowTooltip : true
              " :align="item.align ? item.align : 'center'"
            :header-align="item.headerAlign ? item.headerAlign : 'center'"
            :formatter="item.formatter ? dateFormat : null">
            <template slot-scope="scope">
              <a-tag
                :color="item.getColor ? item.getColor(scope.row[item.dataIndex], scope.row) : scope.row[`${item.dataIndex}Color`]">
                {{ item.getText ? item.getText(scope.row[item.dataIndex], scope.row) : scope.row[item.dataIndex] }}
              </a-tag>
            </template>
          </el-table-column>

          <el-table-column v-if="returnType(item.type ? item.type : '') === 'popover'" :key="'column' + index"
            :prop="item.dataIndex" :label="item.title" :sortable="item.sorter && item.sorter !== null ? item.sorter : false
              " :width="item.width" :min-width="item.minWidth ? item.minWidth : '120'" :fixed="item.fixed"
            :resizable="item.resizable ? item.resizable : true" :show-overflow-tooltip="item.showOverflowTooltip ? item.showOverflowTooltip : true
              " :align="item.align ? item.align : 'center'"
            :header-align="item.headerAlign ? item.headerAlign : 'center'"
            :formatter="item.formatter ? dateFormat : null">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p v-for="(it, _i) in scope.row[`${item.dataIndex}List`]" :key="_i">
                  <span>{{ it.label }}</span>
                  <span>{{ it.value }}</span>
                </p>
                <div slot="reference" class="name-wrapper">
                  <a>{{ "查看" }}</a>
                </div>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column v-if="returnType(item.type ? item.type : '') === 'link'" :key="'column' + index"
            :prop="item.dataIndex" :label="item.title" :sortable="item.sorter && item.sorter !== null ? item.sorter : false
              " :width="item.width" :min-width="item.minWidth ? item.minWidth : '120'" :fixed="item.fixed"
            :resizable="item.resizable ? item.resizable : true" :show-overflow-tooltip="item.showOverflowTooltip ? item.showOverflowTooltip : true
              " :align="item.align ? item.align : 'center'"
            :header-align="item.headerAlign ? item.headerAlign : 'center'"
            :formatter="item.formatter ? dateFormat : null">
            <template slot-scope="scope">
              <a @click="linkClick(scope.row, index)">{{
                item.linkLabel && scope.row[item.dataIndex] ? item.linkLabel : scope.row[item.dataIndex]
              }}</a>
            </template>
          </el-table-column>
          <el-table-column v-if="item.dataIndex === 'actions' && _actionsType && _actionsType.isShow"
            :key="'actions' + index" :width="item.width" :min-width="item.minWidth ? item.minWidth : '150'"
            :fixed="item.fixed !== null ? item.fixed : true" label="操作" align="center" header-align="center">
            <template slot-scope="scope">
              <template>
                <a class="table-edit" :key="'_a' + index" v-for="(item, index) in _actionsType.fns.detailList"
                  style="margin-right: 12px" :disabled="item.EnableFunc ? item.EnableFunc(scope.row) : false"
                  v-if="!item.showFunc || (typeof item.showFunc === 'function' && item.showFunc(scope.row))">
                  <span @click="handleClick(item, scope.$index, scope.row)">
                    <a-icon :type="item.icon" />
                    {{ item.name }}
                  </span>
                </a>
              </template>

              <template>
                <!-- 这是下拉列表 -->
                <!-- :trigger="['click']"  -->
                <a-dropdown v-if="_actionsType.fns.dropList && _actionsType.fns.dropList.length && _actionsType.fns.dropList.length >= 2
                  ">
                  <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                    操作 <a-icon type="down" />
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item :key="'_b' + i" v-for="(item, i) in _actionsType.fns.dropList">
                      <div v-if="typeof item.showFunc != 'function' ||
                        (typeof item.showFunc == 'function' &&
                          item.showFunc(scope.row))
                        ">
                        <div class="drop-item" v-if="item.granted && !item.delete"
                          @click="handleClick(item, scope.$index, scope.row)">
                          <a-icon :type="item.icon" />
                          {{ item.name }}
                        </div>
                        <!--delete-->
                        <a-popconfirm placement="top" v-if="item.delete && item.granted" :okText="l('Ok')"
                          :cancelText="l('Cancel')" @confirm="handleClick(item, scope.$index, scope.row)">
                          <template slot="title">
                            {{
                              item.tipsDesc
                              ? item.tipsDesc
                              : l("ConfirmDeleteWarningMessage")
                            }}
                          </template>
                          <div class="drop-item">
                            <a-icon :type="item.icon ? item.icon : 'delete'" />
                            {{ item.name }}
                          </div>
                        </a-popconfirm>
                      </div>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </template>
              <!-- dropList.length 两个或以下直接显示 -->
              <template>
                <a class="table-edit" :key="'_c' + i" v-for="(_item, i) in _actionsType.fns.dropList">
                  <span @click="handleClick(_item, scope.$index, scope.row)" v-if="_actionsType.fns.dropList && _actionsType.fns.dropList.length && _actionsType.fns.dropList.length < 2
                    ">
                    <a-icon :type="_item.icon" />
                    {{ _item.name }}
                  </span>
                </a>
              </template>
              <!-- delete -->
              <a-popconfirm placement="top" class="table-delete"
                v-if="_actionsType.fns.delete && _actionsType.fns.delete.granted" :okText="l('Ok')"
                :cancelText="l('Cancel')" @confirm="handleClick(_actionsType.fns.delete, scope.$index, scope.row)">
                <template slot="title">
                  {{
                    _actionsType.fns.delete.tipsDesc
                    ? _actionsType.fns.delete.tipsDesc
                    : l("ConfirmDeleteWarningMessage")
                  }}
                </template>
                <a class="table-delete">
                  <a-icon type="delete" />{{ _actionsType.fns.delete.name }}
                </a>
              </a-popconfirm>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>

    <div class="pw">
      <el-pagination v-if="!hidePagination" background @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
        :total="totalItems" :layout="layout">
      </el-pagination>
    </div>
  </div>
</template>


<script>
import { AppComponentBase } from "@/shared/component-base";
import objectHelper from "@/shared/helpers/objectHelper";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
// import { layoutService } from "@/shared/common";
// 请勿随意格式化代码！！！！！！！！！！！！！
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
export default {
  name: "ele-table",
  mixins: [AppComponentBase],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      dafault: 0,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String | Number,
      default: "",
    },
    handleHeight: {
      type: String | Number,
      default: 0,
    },
    maxHeight: {
      type: String | Number,
      default: "",
    },
    border: {
      type: Boolean,
      default: true,
    },
    stripe: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "mini",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    actionsType: {
      type: Object,
      default: () => {
        return {};
      },
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    pageSizes: {
      type: Array,
      default: () => [50, 100, 200, 400, 600,800,1000],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    hideRowSelection: {
      type: Boolean,
      default: false,
    },
    showRowSelectionTips: {
      type: Boolean,
      default: true,
    },
    tableKey: {
      type: String,
      default: null,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    selectType: {
      type: String,
      default: "checkbox",
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper"
    },
    IsClear: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableShow: true,
      rtHeight: 138,
      ltHeight: 220,
      myColumns: [],
      selectedRowKeys: [],
      selectedRows: [], // 所选项item []
      tableWidth: 0,
    };
  },
  watch: {
    tableData (oldData, newData) {
      this.selectedRowKeys.splice(0, this.selectedRowKeys.length);
      this.selectedRows.splice(0, this.selectedRows.length);
      this.$emit("emitSelectChange", {
        selectedRowKeys: this.selectedRowKeys,
        selectedRows: this.selectedRows,
      });
    },
    IsClear (oldData, newData) {
      this.$refs.myTable.clearSelection();
      console.warn('clearselected')
    }
  },
  computed: {
    // collapsed() {
    //   return layoutService.data.collapsed;
    // },
    modalH () {
      return window.innerHeight - window.innerHeight * 0.14 < 600
        ? 600
        : window.innerHeight - window.innerHeight * 0.14;
    },
    tableHeight () {
      if (this.isFull) {
        if (this.showRowSelectionTips && !this.hideRowSelection) {
          return (
            window.innerHeight -
            this.rtHeight -
            70 -
            40 -
            (this.handleHeight ? this.handleHeight : 0) +
            "px"
          );
        } else {
          return (
            window.innerHeight -
            this.rtHeight -
            70 -
            (this.handleHeight ? this.handleHeight : 0) +
            "px"
          );
        }
      } else {
        if (this.handleHeight) {
          return (
            window.innerHeight -
            this.rtHeight -
            70 -
            40 -
            this.handleHeight +
            "px"
          );
        } else {
          if (this.tableData.length) {
            if (this.showRowSelectionTips && !this.hideRowSelection) {
              return (
                window.innerHeight -
                this.rtHeight -
                220 +
                "px"
              );
            } else {
              return (
                window.innerHeight -
                this.rtHeight -
                180 +
                "px"
              );
            }
          } else {
            return "auto";
          }
        }
      }
    },
    rowStyle () {
      let obj = {
        height: "0px",
      };
      return obj;
    },
    cellStyle () {
      let obj = {
        padding: "4px 0px",
        fontSize: "13px",
      };
      return obj;
    },
    headerRowStyle () {
      let obj = {
        fontWeight: "bold",
        fontSize: "13px",
        color: "#1b2934",
        padding: "4px 0",
        background: "#eef1f6",
      };
      return obj;
    },
    _actionsType () {
      let obj = { ...this.actionsType }
      if (Object.keys(this.actionsType).length) {
        let dropList = []
        let detailList = []
        if (obj.fns.dropList && obj.fns.dropList.length) {
          obj.fns.dropList.map(item => {
            if (item.granted === true) {
              dropList.push(item)
            }
          })
        }
        if (obj.fns.detailList && obj.fns.detailList.length) {
          obj.fns.detailList.map(item => {
            if (item.granted === true) {
              detailList.push(item)
            }
          })
        }
        obj.fns.dropList = [...dropList]
        obj.fns.detailList = [...detailList]
        let _del = obj.fns.delete && obj.fns.delete.granted ? true : false
        if (dropList.length || detailList.length || _del) {
          obj.isShow = true
        } else {
          obj.isShow = false
        }
        // console.log('obj:', obj)
        return { ...obj }
      }
    },
  },
  created () {
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    if ((!this.columns || !this.columns.length) && this.tableKey) {
      this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
      this._getFieldRoleByRoleId();
    } else if (
      !!this.actionsType &&
      objectHelper.countProperties(this.actionsType) > 0
    ) {
      //自定义列头的 传递了操作的 但是列头中没有 操作列的 属性补完
      let actionCol = this.columns.find((item) => {
        return item.dataIndex == "actions";
      });
      if (typeof actionCol == "undefined") {
        this.columns.push({
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: true,
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "actions" },
        });
      }
    }
  },
  mounted () {
    if (this.columns && this.columns.length) {
      let columns = [...this.columns];
      if (this.actionsType && this.actionsType.fns) {
        let arr =
          this.actionsType.fns.dropList && this.actionsType.fns.dropList.length
            ? this.actionsType.fns.dropList
            : [];
        let detailList =
          this.actionsType.fns.detailList &&
            this.actionsType.fns.detailList.length
            ? this.actionsType.fns.detailList
            : [];
        if (
          arr.length === arr.filter((v) => !v.granted).length &&
          detailList.length === detailList.filter((v) => !v.granted).length &&
          columns[columns.length - 1].dataIndex === "actions"
        ) {
          this.myColumns = this.evoColumns(
            columns.filter((v) => v.dataIndex !== "actions")
          );
        } else {
          this.myColumns = this.evoColumns(columns);
        }
      } else {
        this.myColumns = this.evoColumns(columns);
      }
    }
  },
  methods: {
    // 请勿随意格式化代码！！！！！！！！！！！！！
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    // 请勿随意格式化代码
    handleHeaderDragend (newWidth, oldWidth, column, event) {
      this.$refs.myTable.doLayout()
    },
    handleSortChange ({ column, prop, order }) {
      console.log(column)
      console.log(prop)
      console.log(order)

    },
    handleHeaderClick (column, event) {
      // console.log(column)
      // console.log(event)

    },
    handleClick (btnFns, index, row) {
      // console.log(btnFns);
      // console.log(index);
      // console.log(row);
      btnFns.fn(row);
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.$emit("emitShowSizeChange", {
        current: this.currentPage,
        size: val,
      });
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.$emit("emitOnPageChange", {
        page: val,
        skipCount: this.pageSize,
        pageSize: this.pageSize,
      });
    },
    handleSelectAll (selection) {
      // console.log("handleSelectAll", selection);
      const selectedRowKeys = selection.map((v) => {
        return v.id;
      })
      this.$emit("emitSelectChange", {
        selectedRowKeys: selectedRowKeys,
        selectedRows: selection,
      });
      this.selectedRowKeys = selectedRowKeys
    },
    handleSelectChange (selection, row) {
      // console.log("handleSelectChange", selection, row);
      let _selection = []
      if (this.selectType === 'checkbox') {
        _selection = selection
      } else {
        _selection = selection.length ? [row] : []
        if (selection.length > 1) {
          this.$refs.myTable.clearSelection()
          this.$refs.myTable.toggleRowSelection(selection.pop())
        }
      }
      const selectedRowKeys = _selection.map((v) => {
        return v.id;
      })

      this.$emit("emitSelectChange", {
        selectedRowKeys: selectedRowKeys,
        selectedRows: _selection,
      });
      this.selectedRowKeys = selectedRowKeys
    },
    linkClick (item, index) {
      this.$emit("emitLinkClick", { item: item, index: index });
    },
    clearData () {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.$refs.myTable.clearSelection();
      this.$emit("emitSelectChange", {
        selectedRowKeys: [],
        selectedRows: [],
      });
    },
    refreshData () {
      this.$emit("emitRefreshData");
    },
    _doLayout () {
      this.$refs.myTable.doLayout()
    },
    evoColumns (arr) {
      return arr.map((item) => {
        if (item.dataIndex !== "actions") {
          return {
            ...item,
            align: "center",
            ellipsis: true,
            sorter: item.sorter ? item.sorter : false,
            // width: item.width ? item.width : this._columnsWidth,
            width: this._columnsWidth(item.width)
          };
        } else if (item.dataIndex === "actions") {
          return {
            ...item,
            align: "center",
            fixed: "right",
            width: item.width ? item.width : '210',
          }; //this.actionsType.fns.dropList
        }
      });
    },
    initColumns () {
      // console.log(this.tableKey);
      let userFieldRole = JSON.parse(localStorage.userFieldRole);
      let roleFocus = userFieldRole.filter((value, index) => {
        return value.key == this.tableKey;
      });
      let columnsFocus = [];
      if (roleFocus && roleFocus.length > 0) {
        columnsFocus = roleFocus[0].items.filter((value, index) => {
          return value.isCheck === true;
        });
      }
      // console.log(JSON.stringify(columnsFocus))
      let columns = [];
      columnsFocus.map((item) => {
        let myDataIndex;
        let sorterFn = (a, b) => a.length - b.length;
        if (item.key !== "Id" && item.isCheck === true) {
          myDataIndex = item.key.replace(
            item.key[0],
            item.key[0].toLowerCase()
          );
          columns.push({
            title: item.name,
            dataIndex: myDataIndex,
            sorter: true,
            align: "center",
            // width: 150,
            // scopedSlots: { customRender: item.key },
            // sorter: sorterFn,
          });
        }
      });
      if (columns.length) {
        columns.push({
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: true,
          fixed: "right",
          align: "center",
          // scopedSlots: { customRender: "actions" },
        });
      }

      this.myColumns = columns;
    },
    _getFieldRoleByRoleId () {
      this.spinning = true;
      this.commonService
        .getUserFieldRole()
        .finally(() => {
          this.spinning = false;
        })
        .then((result) => {
          localStorage.userFieldRole = JSON.stringify(result);
          this.initColumns();
        });
    },
    dateFormat (row, column) {
      let date = row[column.property];
      if (date === undefined || date === null) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    dateTimeFormat (date, format) {
      if (!date || date === '') {
        return "";
      }
      return moment(date).format(format);
    },
    returnType (t) {
      switch (t) {
        case "tag":
          return "tag";
        case "actions":
          return "actions";
        case "link":
          return "link";
        case "popover":
          return "popover";
        case "custom":
          return "custom";
        case "time":
          return "time";
        case "date":
          return "date";
        case "image":
          return "image";
        default:
          return "normal";
      }
    },
    _columnsWidth (w) {
      if (this.columns.length) {
        let tw = this.$el.querySelector("#tw");
        let twWidth = tw.clientWidth
        let _column = this.columns.filter(v => v.dataIndex !== 'actions')
        let _action = this.columns.filter(v => v.dataIndex === 'actions')
        if (this._actionsType && this._actionsType.isShow) {
          twWidth = twWidth - Number(_action[0].width ? _action[0].width : 211)
        }
        let width = twWidth / _column.length - 1
        if (w && Number(w) > width) {
          return `${w}`
        } else {
          return width > 160 ? `${width}` : '160'
        }
      } else {
        return '160'
      }
    },
  },
};
</script>

<style lang="less">
// 请勿随意格式化代码！！！！！！！！！！！！！
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
// 请勿随意格式化代码
.ele-table {
  padding: 0 5px;

  .slot-w {
    padding: 5px 0;
  }

  .tw {}

  .pw {
    padding: 5px 0;
    text-align: right;
  }

  .drop-item {
    padding: 5px 12px;

    &:hover {
      color: blue;
    }
  }

  .ant-dropdown-link {
    &:hover {
      color: blue;
    }
  }

  .table-edit,
  .table-delete {
    text-align: center;

    // width: 100%;
    &:hover {
      color: blue;
    }

    span {
      margin-left: 3px;
    }

    i {
      // padding: 0 2px;
    }
  }

  .table-delete {
    margin-left: 6px;
    // display: block;
  }
}
</style>