/**
 * 应用使用的常量
 */
 export class AppConsts {
    /**
     * 远程服务器地址
     */
    static remoteServiceBaseUrl: string;

    /**
     * 门户地址
     */
    static portalBaseUrl: string;

    /**
     * 当前应用地址
     */
    static appBaseUrl: string;

    /**
     * 上传文件api路径
     */
    static uploadApiUrl = '/api/File/Upload'

    static fileServerBaseUrl = "http://localhost:6298";

    static uploadFileApiUrl = "/api/Upload";

    /**
     * 上传文件baseUrl
     */
    static uploadBaseUrl: string;

    /**
     * 移动端api路径
     */
    static mobileApiBaseUrl: string

    /**
     * 个人头像上传最大MB
     */
    static maxProfilPictureMb = 1;

    /**
     * 后端本地化和前端angular本地化映射
     */
    static localeMappings: any;

    /**
     * 后端本地化和ng-zorro本地化映射
     */
    static ngZorroLocaleMappings: any;

    /**
     * 后端本地化和ng-alian本地化映射
     */
    static ngAlainLocaleMappings: any;

    /**
     * 后端本地化和moment.js本地化映射
     */
    static momentLocaleMappings: any;

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Framework'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    /**
     * 数据表格设置
     */
    // tslint:disable-next-line:member-ordering
    static readonly grid = {
        /**
         * 每页显示条目数
         */
        defaultPageSize: 10,
        /**
         * 每页显示条目数下拉框值
         */
        defaultPageSizes: [5, 10, 15, 20, 25, 30, 50, 80, 100]
    };

    /**
     * top bar通知组件中获取通知数量
     */
    static readonly notificationCount = 5;
}
